<template>
  <div class="main-content">
    <div class="content-block">
      <h2 class="lg-title">
        <div class="title-word">匯款資訊</div>
      </h2>
      <div class="input-block">
        <div class="input-wrap">
          <input type="text" placeholder="匯款帳號後五碼" v-model="bankno" />
        </div>
      </div>
      <h2 class="lg-title">
        <div class="title-word">寄送資訊</div>
      </h2>
      <ul class="address-tab" role="tablist">
        <li
          :class="{ active: index == 0 }"
          role="presentation"
          v-for="(tab, index) in taketabs"
          :key="`tabk${index}`"
        >
          <a
            :href="`#tab0${index + 1}`"
            aria-controls="home"
            role="tab"
            data-toggle="tab"
          >
            {{ tab }}
          </a>
        </li>
      </ul>
      <div class="tab-content-wrapper">
        <div
          v-for="(setting, index) in addressList"
          :key="index"
          class="tab-content tab-pane fade in"
          :class="{ active: index == 0 }"
          :id="`tab0${index + 1}`"
          role="tabpanel"
        >
          <div class="input-block">
            <div class="input-wrap">
              <input
                type="text"
                :placeholder="`請輸入收件人名稱${index + 1}`"
                v-model="setting.name"
              />
            </div>
            <div class="input-wrap">
              <input
                type="text"
                placeholder="請輸入地址"
                v-model="setting.address"
              />
            </div>
            <div class="input-wrap">
              <input
                type="text"
                placeholder="請輸入市話"
                v-model="setting.phone"
              />
            </div>
            <div class="input-wrap">
              <input
                type="text"
                placeholder="請輸入手機1"
                v-model="setting.mobile"
              />
            </div>
            <div class="input-wrap">
              <input
                type="text"
                placeholder="請輸入手機2"
                v-model="setting.mobile2"
              />
            </div>
            <div class="input-wrap">
              <textarea
                placeholder="請輸入備註"
                v-model="setting.remark"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-block nofloat">
      <a class="btn-cta" @click="submit" href="#">
        儲存
      </a>
    </div>
    <MessageAlert
      :show="showModal"
      @close-modal="confirmMsg"
      :msgTitle="msgTitle"
      :msgContent="msgContent"
    ></MessageAlert>
  </div>
</template>

<script>
import memberSettingsMixin from '@/mixins/member-settings';
import FollowSettingMixin from '@/mixins/follow-setting';
import MessageAlert from '@/components/Modals/ModalMessageAlert';

export default {
  mixins: [memberSettingsMixin, FollowSettingMixin],
  components: {
    MessageAlert
  }
};
</script>
