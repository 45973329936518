import { getSetting, updateSetting } from '@/api/member';

export default {
  data() {
    return {
      key: 'default_follow_group_settings',
      taketabs: ['地址一', '地址二', '地址三'],
      showModal: false,
      msgTitle: null,
      msgContent: null
    };
  },
  computed: {
    addressList() {
      if (this.settings.length > 0) {
        return this.settings.filter((setting, index) => index < 3);
      }
      return [];
    },
    bankno: {
      get() {
        if (this.settings.length > 0) {
          let settings = this.settings.filter((setting, index) => index == 3);

          if (settings.length > 0) {
            return settings[0].bankno;
          }
        }
        return null;
      },
      set(newValue) {
        this.$set(this.settings, 3, {
          bankno: newValue
        });
      }
    }
  },
  methods: {
    getDataSuccessCallback(data) {
      this.settings = data ? data : this.getDefaultSetting();
    },
    getDefaultSetting() {
      return [
        {
          name: '',
          address: '',
          phone: '',
          mobile: '',
          remark: ''
        },
        {
          name: '',
          address: '',
          phone: '',
          mobile: '',
          remark: ''
        },
        {
          name: '',
          address: '',
          phone: '',
          mobile: '',
          remark: ''
        },
        {
          bankno: ''
        }
      ];
    },
    submit() {
      this.updateSettings(
        data => {
          this.showSuccessMsg();
        },
        null,
        true
      );
    },
    confirmMsg() {
      this.$router.push({ name: 'MemberCenter' });
    },
    showSuccessMsg() {
      this.showModal = true;
      this.msgTitle = '提示';
      this.msgContent = '更新成功';
    }
  }
};
